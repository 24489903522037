<template>
  <div class="container">
    <div class="top">
      <div class="title">{{ $t("guide_new.t1") }}</div>
      <div class="process">
        <div class="font" style="margin-right: 40px">
          {{ $t("guide_new.t2") }}{{ percent }} %
        </div>
        <el-progress
          class="progress"
          :percentage="percent"
          :show-text="false"
          :stroke-width="12"
          color="#2A82E4"
        ></el-progress>
        <div class="font" style="margin-left: 40px">
          <img src="@/assets/img/M.png" style="width: 15px; height: 15px" />
          {{ $t("guide_new.t3") }} {{ b }} M {{ $t("guide_new.t4") }}
        </div>
      </div>
    </div>
    <div class="box zdh_guide">
      <div
        style="
          display: flex;
          width: 90%;
          margin-top: 20px;
          font-size: 18px;
          font-weight: bold;
        "
      >
        {{ $t("guide_new.t5") }}
      </div>
      <div
        style="display: flex; align-items: center; width: 90%; margin: 20px 0"
      >
        <div style="font-weight: bold; font-size: 16px">
          {{ $t("guide_new.t6") }}
        </div>
        <div class="huode" style="margin: 0 10px">{{ $t("guide_new.t7") }}</div>

        <div class="huode">>4000 M{{ $t("guide_new.t4") }}</div>
      </div>
      <el-collapse accordion class="collapse">
        <el-collapse-item
          :name="item.index"
          v-for="item in table"
          :key="item.index"
          style="position: relative"
        >
          <div class="collapse-title" slot="title">
            <div style="margin: 10px 0">
              <div class="p1">{{ item.index + "、" + item.name }}</div>
              <div class="p2">
                {{ $t("guide_new.t8") }}{{ item.time }}{{ $t("guide_new.t9") }}
              </div>
            </div>
            <div class="huode" v-if="item.key == 'FILE_TYPE_SETTING'">
              {{ $t("guide_new.t10") }}
            </div>
            <div class="huode" v-else>
              {{ $t("guide_new.t12") }}{{ item.earn }}{{ $t("guide_new.t11") }}
            </div>

            <i
              v-show="item.status"
              class="iconfont icon-wanchengrenwu wancheng"
              style="font-size: 26px; color: #5ec697"
            ></i>
          </div>
          <div class="cont">
            <el-button type="primary" @click="kaishibofang(item)">{{
              $t("guide_new.t13")
            }}</el-button>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="70%"
      top="5vh"
      :close-on-click-modal="false"
      class="zdh_dis_guide"
    >
      <div slot="title">
        {{ video_data.name }}

        <el-button
          v-show="!video_data.jump"
          style="
            margin-left: 10px;
            color: black;
            background-color: #ffc300;
            border-color: #ffc300;
          "
          @click="tiyan()"
          >{{ $t("guide_new.t14") }}</el-button
        >
      </div>
      <video
        v-if="dialogVisible"
        ref="videoElement"
        width="100%"
        height="100%"
        controls
        autoplay
        @timeupdate="onTimeUpdate"
        :key="key_video"
      >
        <source :src="video_data.url" type="video/mp4" v-if="$t('guide_new.lang') == 'zh'" />
        <source :src="video_data.en_url" type="video/mp4" v-else/>

        
      </video>
    </el-dialog>

    <!-- <div class="intro"></div> -->
  </div>
</template>

<script>
import { get_ed, get_b } from "@/network/guide/index";
import { get_all_file, get_all_node } from "@/network/workstation/index.js";
import vmson from "@/utils/vmson";
import { ed } from "@/network/guide/index.js";

export default {
  data() {
    return {
      dialogVisible: false,
      key_video: 0,
      video_data: {
        name: "",
        url: "",
      },
      percent: 50,
      b: 100,
      wancheng: [],
      only_once: false,
      last_time: 0,
      aii_see_time: 0,
      table: [
        {
          index: 1,
          name: this.$t("guide_new.tip1"),
          earn: 500,
          key: "NEW_FILE",
          route: "global_tip_1",
          time: 1,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/1%E6%96%B0%E5%BB%BA%E6%96%87%E4%BB%B6.mp4",
          en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/1new%20file%20at%20home%20page.mp4'
        },
        {
          index: 2,
          earn: 1000,
          name: this.$t("guide_new.tip2"),
          key: "MINDMAP_OPERATION",
          route: "global_tip_2",
          time: 3,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/2%E6%80%9D%E7%BB%B4%E5%AF%BC%E5%9B%BE.mp4",
          en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/2Mind%20map.mp4'
        },
        {
          index: 3,
          earn: 300,
          name: this.$t("guide_new.tip3"),
          key: "NEW_FILE_WITH_TEMPLAE",
          route: "global_tip_3",
          time: 2,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/3%E5%9F%BA%E4%BA%8E%E6%A8%A1%E7%89%88%E6%96%B0%E5%BB%BA.mp4",
          en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/3Create%20a%20new%20file%20based%20on%20the%20template.mp4'
        },
        {
          index: 4,
          earn: 400,
          name: this.$t("guide_new.tip4"),
          key: "MINDMAP_WORD_MODEL",
          route: "global_tip_4",
          time: 3,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/4%E8%A7%86%E5%9B%BE%E5%88%87%E6%8D%A2word.mp4",
          en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/4Switch%20view.mp4'
        },
        {
          index: 5,
          earn: 100,
          name: this.$t("guide_new.tip5"),
          key: "NODE_DETAIL",
          route: "global_tip_5",
          time: 3,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/5%E8%8A%82%E7%82%B9%E5%90%84%E4%B8%AA%E6%A8%A1%E5%9D%97%E4%BB%8B%E7%BB%8D.mp4",
        en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/5Node%20Module%20introduction.mp4'
        },
        {
          index: 6,
          earn: 500,
          name: this.$t("guide_new.tip6"),
          key: "REVIEW",
          route: "global_tip_6",
          jump: true,
          time: 5,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/6%E8%AF%84%E5%AE%A1.mp4",
          en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/6Reviews.mp4'
        },
        {
          index: 7,
          earn: 500,
          name: this.$t("guide_new.tip7"),
          key: "AGILE_BOARD",
          route: "global_tip_7",
          jump: true,
          time: 3,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/7%E6%95%8F%E6%8D%B7%E7%9C%8B%E6%9D%BF.mp4",
          en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/7Agile.mp4'
        },
        {
          index: 8,
          earn: 300,
          name: this.$t("guide_new.tip8"),
          key: "GANTT_BOARD",
          route: "global_tip_8",
          jump: true,
          time: 3,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/8%E7%94%98%E7%89%B9%E5%9B%BE.mp4",
          en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/8Gantt%20chart.mp4'
        },
        {
          index: 9,
          earn: 300,
          name: this.$t("guide_new.tip9"),
          key: "QUERY_CONDITION",
          route: "global_tip_9",
          time: 3,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/9%E7%AD%9B%E9%80%89%E6%9D%A1%E4%BB%B6%E9%99%8D%E9%80%9F%E7%89%88.mp4",
       en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/9Filter.mp4'
       },
        {
          index: 10,
          earn: 500,
          name: this.$t("guide_new.tip10"),
          key: "STATISTICS_BOARD",
          jump: true,
          route: "global_tip_10",
          time: 3,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/10%E6%95%B0%E6%8D%AE%E6%8A%A5%E8%A1%A8.mp4",
          en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/10Dashboard.mp4'
        },
        {
          index: 11,
          earn: 0,
          name: this.$t("guide_new.tip11"),
          key: "FILE_TYPE_SETTING",
          jump: true,
          route: "global_tip_11",
          time: 4,
          url: "http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/11%E6%96%87%E4%BB%B6%E7%B1%BB%E5%9E%8B%E8%AE%BE%E7%BD%AE.mp4",
      en_url:'http://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/guide/11File%20type.mp4'
      },
      ],
    };
  },
  mounted() {
    get_ed().then((res) => {
      this.percent = Math.round((100 * res.length) / this.table.length, 2);

      let s = [];
      res.forEach((ele) => {
        s.push(ele.guideType);
      });

      this.table.forEach((item) => {
        item.status = false;

        if (s.includes(item.key)) {
          item.status = true;
        }
      });
    });
    get_b().then((res) => {
      this.b = res;
    });
  },
  methods: {
    onTimeUpdate() {
      if (!this.dialogVisible) {
        console.log("time uodate");

        return;
      }
      if (this.only_once) {
        return;
      }
      const videoElement = this.$refs.videoElement;
      const duration = videoElement.duration;
      const currentTime = videoElement.currentTime;
      if (Math.abs(this.last_time - currentTime) > 2) {
        console.log("不对劲");
      } else {
        this.aii_see_time += Math.abs(this.last_time - currentTime);
      }
      console.log(this.aii_see_time);

      this.last_time = currentTime;
      if (
        this.video_data.route == "global_tip_11" ||
        this.video_data.route == "global_tip_10" ||
        this.video_data.route == "global_tip_8" ||
        this.video_data.route == "global_tip_7" ||
        this.video_data.route == "global_tip_6"
      ) {
        if (currentTime / duration > 0.9) {
          this.only_once = true;
          ed({ guideType: this.video_data.key }).then((b) => {
            if (!b.success) {
              return;
            }
            if (this.video_data.route == "global_tip_11") {
              vmson.$emit("fireword_and_reward", "zdh_11");
            } else {
              vmson.$emit("fireword_and_reward", b.reward);
            }
          });
        }
      }
    },
    tiyan() {
      // this.$router.push({
      //   path:'/'+this.get_pid() + "/home/my_file",
      // })

      // localStorage.setItem("global_tip", "global_tip_3");
      if (
        this.video_data.route == "global_tip_1" ||
        this.video_data.route == "global_tip_3"
      ) {
        window.open(
          this.$router.resolve({
            path: "/" + this.get_pid() + "/home/my_file",
            query: {
              tool_tip: this.video_data.route,
            },
          }).href,
          "_blank" // 这将在新窗口或新标签页中打开链接
        );
      }
      if (
        this.video_data.route == "global_tip_4" ||
        this.video_data.route == "global_tip_2"
      ) {
        get_all_file(0, 1).then((res) => {
          localStorage.setItem("default_word_or_map", "myMind");
          const { href } = this.$router.resolve({
            path: `/${res.content[0].projectId}/myMind/${res.content[0].key}`,
            query: {
              tool_tip: this.video_data.route,
            },
          });
          window.open(href, "_blank");
        });
      }
      if (this.video_data.route == "global_tip_5") {
        get_all_node(0, 1).then((res) => {
          const { href } = this.$router.resolve({
            path: `/${res.content[0].projectId}/nodes/key/${res.content[0].key}`,
            query: {
              tool_tip: this.video_data.route,
            },
          });
          window.open(href, "_blank");
        });
      }
      if (this.video_data.route == "global_tip_8") {
        window.open(
          this.$router.resolve({
            path: "/621b8b27-1899-459a-90eb-5887e8213f76/home/gantt/enterOwn",
            query: {
              tool_tip: this.video_data.route,
            },
          }).href,
          "_blank" // 这将在新窗口或新标签页中打开链接
        );
      }
      if (this.video_data.route == "global_tip_9") {
        window.open(
          this.$router.resolve({
            path: "/" + this.get_pid() + "/home/node",
            query: {
              tool_tip: this.video_data.route,
            },
          }).href,
          "_blank" // 这将在新窗口或新标签页中打开链接
        );
      }
    },
    kaishibofang(item) {
      this.only_once = false;
      this.aii_see_time = 0;
      this.last_time = 0;
      this.key_video++;
      this.video_data = item;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss">
.zdh_dis_guide {
  .el-dialog__body {
    padding: 0 !important;
  }
}
.el-collapse-item .is-active {
  background: #f7f7f7;
}
.zdh_guide {
  .el-collapse {
    overflow: auto;
    margin-bottom: 20px;
  }
  .collapse-title {
    flex: 1 0 90%;
    order: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;

    .p1 {
      font-size: 18px;
      line-height: normal;
      font-weight: bold;
    }
    .p2 {
      font-size: 14px;
      color: #747474;
      line-height: normal;
      margin-top: 5px;
    }
  }
  .el-collapse-item {
    border: 2px solid #e3e3e5;
    border-radius: 4px;
    margin: 20px 0;
  }
  .el-collapse-item__header {
    height: auto;
    flex: 1 0 auto;
    order: -1;
  }
  .el-collapse-item__content {
    background: #f7f7f7;
    padding: 0;
  }

  .el-collapse-item__arrow {
    margin-left: 10px;
    font-weight: bold;
  }
}
</style>
<style lang="scss" scoped>
.intro_box {
  width: 200px;
  z-index: 9999;
  height: 100px;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}
.intro {
  z-index: 9998;
  background: #7474746a;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.wancheng {
  position: absolute;
  bottom: -10px;
  right: 0;
}
.huode {
  background: #fbefcc;
  border-radius: 4px;
  line-height: normal;
  padding: 5px 10px;
  margin-right: 30px;
}
.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  background: rgb(249, 251, 253);
}
.cont {
  display: flex;
  margin-left: 30px;
  padding-bottom: 10px;
}
.box {
  flex-grow: 1;
  height: 50vh;
  background: white;
  margin: 15px;
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
}
.box {
  .collapse {
    width: 90%;
  }
}
.top {
  padding: 20px 35px;
  background: white;
  .process {
    display: flex;
    margin: 0 auto;
    margin-top: 10px;

    align-items: center;
    justify-content: space-between; /* Ensure spacing is distributed evenly */
    width: calc(90% + 30px); /* Make the process div occupy full width */
    .progress {
      flex-grow: 1; /* Allow progress to grow and fill remaining space */
    }
    .font {
      font-size: 18px;
      font-weight: bold;
      // margin: 10px;
    }
  }
  .title {
    font-weight: bold;
    text-align: left;
    font-size: 36px;
    font-family: "思源黑体";
  }
}
</style>
